"use client";

import { Button } from "@/components/ui/button";
import HomeButton from "@/components/ui/buttons/home";
import { mainSidebarConfig } from "@/config/main";
import { cn } from "@/lib/utils";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { v4 } from "uuid";

type PostSideBarProps = {
  horizontal?: boolean;
  className?: string;
};

const PostSideBar = ({ horizontal = false, className }: PostSideBarProps) => {
  const currentPath = usePathname();

  return (
    <nav className="grid h-full p-2">
      <div
        className={cn(
          !horizontal ? "flex flex-col" : "flex-row xs:flex sm:hidden",
          "gap-2",
          className,
        )}
      >
        {horizontal && <HomeButton />}
        {mainSidebarConfig.map((item) => {
          return (
            <Button
              key={v4()}
              variant="ghost"
              size="icon"
              className={cn(
                `rounded-lg`,
                currentPath === item.slug ? "bg-muted" : "",
              )}
              aria-label={item.title}
              title={item.title}
              asChild
            >
              <Link href={item.slug}>
                <item.icon className="size-5" />
              </Link>
            </Button>
          );
        })}
      </div>
    </nav>
  );
};

export default PostSideBar;
