"use client";

import { Button } from "@/components/ui/button";
import { Home } from "lucide-react";
import Link from "next/link";
import { usePathname } from "next/navigation";

const HomeButton = () => {
  const path = usePathname();

  return (
    <Button
      variant="outline"
      size="icon"
      aria-label="Home"
      className={path === "/" ? "bg-muted" : ""}
      asChild
    >
      <Link href="/">
        <Home className="size-5" />
      </Link>
    </Button>
  );
};

export default HomeButton;
