import(/* webpackMode: "eager" */ "/vercel/path0/components/main/header/navigations/main-desktop-navigation.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/main/header/navigations/main-mobile-navigation.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/main/sidebars/post-sidebar.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/shared-back-button.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/shared-categories-select.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/shared-error.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/shared-pagination.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/shared-table-empty.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/buttons/home.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/tabs.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/config/main/main-sidebar-config.ts");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/nextjs-toploader/dist/index.js")